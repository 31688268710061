import getFormattedDate from "@/utils/getFormattedDate.js";
import nl2br from "@/utils/nl2br.js";

export default {
  namespaced: true,
  state: {
    news: [],
    detailNews: null,
    errors: {
      getNews: {},
      getDetailNews: {},
    },
  },
  getters: {
    sortedNews(state) {
      let arr = [...state.news].sort(function (a, b) {
        return new Date(b.date_time) - new Date(a.date_time);
      });

      arr = arr.map((item) => {
        return {
          ...item,
          simple_text: nl2br(item.simple_text),
          formatted_date: getFormattedDate(item.date_time),
        };
      });
      return arr;
    },
    mainNews(state, getters) {
      return getters.sortedNews.length ? getters.sortedNews[0] : null;
    },
    otherNews: (state, getters) => (id) => {
      return getters.sortedNews.filter((item) => item.id !== parseInt(id));
    },
    detailNews(state) {
      return state.detailNews
        ? {
            ...state.detailNews,
            simple_text: nl2br(state.detailNews.simple_text),
            main_text: nl2br(state.detailNews.main_text),
            formatted_date: getFormattedDate(state.detailNews.date_time),
          }
        : null;
    },
  },
  mutations: {
    SET_NEWS(state, payload) {
      state.news = payload;
    },
    SET_DETAIL_NEWS(state, payload) {
      state.detailNews = payload;
    },
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
  },
  actions: {
    async getNews({ commit, dispatch }) {
      dispatch("resetNews");

      const url = `/news`;

      try {
        const resp = await this.$axios.get(url);
        commit("SET_NEWS", resp.data);
      } catch (e) {
        if (e.response && e.response.data) {
          console.log(e.response.data);
          commit("SET_ERRORS", {
            key: "getNews",
            val: e.response.data,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "getNews",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    async getDetailNews({ commit, dispatch }, id) {
      dispatch("resetDetailNews");

      const url = `/newsitem`;
      const options = { item_id: id };

      try {
        const resp = await this.$axios.get(url, { params: options });
        commit("SET_DETAIL_NEWS", resp.data);
      } catch (e) {
        if (e.response && e.response.data) {
          console.log(e.response.data);
          commit("SET_ERRORS", {
            key: "getDetailNews",
            val: e.response.data,
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "getDetailNews",
            val: { error: "Ошибка" },
          });
        }
      }
    },
    resetNews({ commit }) {
      commit("SET_ERRORS", {
        key: "getNews",
        val: {},
      });
      commit("SET_NEWS", []);
    },
    resetDetailNews({ commit }) {
      commit("SET_ERRORS", {
        key: "getDetailNews",
        val: {},
      });
      commit("SET_DETAIL_NEWS", null);
    },
  },
};
