<template>
  <div class="guide">
    <GuideTours />

    <GuideHotels />

    <GuideRestaurants />

    <GuideFood />

    <GuideApp />
  </div>
</template>

<script>
import GuideTours from "@/components/partials/GuideTours.vue";
import GuideHotels from "@/components/partials/GuideHotels.vue";
import GuideRestaurants from "@/components/partials/GuideRestaurants.vue";
import GuideFood from "@/components/partials/GuideFood.vue";
import GuideApp from "@/components/partials/GuideApp.vue";

export default {
  name: "Guide",
  components: {
    GuideTours,
    GuideHotels,
    GuideRestaurants,
    GuideFood,
    GuideApp,
  },
};
</script>
