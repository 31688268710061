<template>
  <div class="guide-section js-trigger-path" data-name="restaurants">
    <div class="container">
      <div class="guide-content">
        <div class="guide-content__row guide-content__row--start">
          <div class="guide-content__col">
            <h2 class="guide-title">Где поесть?</h2>
            <div class="guide-descr guide-descr--restaurants">
              Байкальский регион предлагает вам целый спектр гастрономических
              развлечений, начиная от традиционных бууз и заканчивая блюдами
              вегетерианской кухни. Не обойти стороной и напитки — здесь вам
              нальют превосходный чай, приготовят жаждоутоляющую арсу, а
              любителям крепкого непременно стоит отведать тарасун.
            </div>

            <div class="guide-restaurants-chips">
              <div class="guide-restaurants-chips__item">
                <div
                  class="guide-restaurants-chips__icon guide-restaurants-chips__icon--tray"
                ></div>
                <div class="guide-restaurants-chips__descr">
                  Уникальная возможность проникнуться духом региона через
                  местную кухню, забронировать столик
                </div>
              </div>

              <div class="guide-restaurants-chips__item">
                <div
                  class="guide-restaurants-chips__icon guide-restaurants-chips__icon--binoculars"
                ></div>
                <div class="guide-restaurants-chips__descr">
                  Пообедать в настоящей юрте или, может, все-таки в классическом
                  ресторане? Лучше один раз увидеть — воспользуйтесь режимом
                  просмотра в 3D
                </div>
              </div>

              <div class="guide-restaurants-chips__item">
                <div
                  class="guide-restaurants-chips__icon guide-restaurants-chips__icon--petard"
                ></div>
                <div class="guide-restaurants-chips__descr">
                  В рамках квеста доступны дополнительные бонусы
                </div>
              </div>
            </div>
          </div>

          <div class="guide-content__col">
            <div class="guide-restaurants__img-container">
              <img
                src="@/assets/img/home-restaurants.jpg"
                alt=""
                class="guide-restaurants__img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideRestaurants",
};
</script>
