<template>
  <div class="home-path">
    <div
      class="home-path-point home-path-point--tour js-path js-path--tour"
      @click="scrollTo('tour')"
    >
      <div class="home-path-point__icon"></div>
      <div class="home-path-point__text">Пример тура</div>
    </div>

    <div
      class="home-path-point home-path-point--reverse home-path-point--hotels js-path js-path--hotels"
      @click="scrollTo('hotels')"
    >
      <div class="home-path-point__icon"></div>
      <div class="home-path-point__text">Отели</div>
    </div>

    <div
      class="home-path-point home-path-point--restaurants js-path js-path--restaurants"
      @click="scrollTo('restaurants')"
    >
      <div class="home-path-point__icon"></div>
      <div class="home-path-point__text">Рестораны</div>
    </div>

    <div
      class="home-path-point home-path-point home-path-point--reverse home-path-point--food js-path js-path--food"
      @click="scrollTo('food')"
    >
      <div class="home-path-point__icon"></div>
      <div class="home-path-point__text">Заказ еды</div>
    </div>

    <div
      class="home-path-point home-path-point--column home-path-point--go js-path js-path--app"
      @click="scrollTo('app')"
    >
      <div class="home-path-point__icon"></div>
      <div class="home-path-point__text">Отправиться в тур</div>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";

import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import { ScrollToPlugin } from "gsap/all";
gsap.registerPlugin(ScrollToPlugin);

export default {
  name: "HomePath",
  mounted() {
    if (window.matchMedia("(min-width: 1200px)").matches) {
      this.init();
    }
  },
  methods: {
    init() {
      gsap.utils.toArray(".js-trigger-path").forEach((el) => {
        ScrollTrigger.create({
          trigger: el,
          // markers: true,
          start: "top 50%",
          end: () => "+=" + el.offsetHeight,
          toggleClass: {
            targets: ".js-path--" + el.dataset.name,
            className: "home-path-point--active",
          },
        });
      });

      ScrollTrigger.create({
        trigger: ".footer",
        // markers: true,
        // start: "top 90%",
        toggleClass: {
          targets: ".home-path",
          className: "home-path--scrolled",
        },
      });
    },
    scrollTo(val) {
      const el = document.querySelector("[data-name=" + val + "]");
      gsap.to(window, { duration: 1, scrollTo: { y: el, offsetY: -180 } });
    },
  },
};
</script>
