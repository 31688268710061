<template>
  <div class="guide-section js-trigger-path" data-name="hotels">
    <div class="container">
      <div class="guide-content">
        <div class="guide-content__row">
          <div class="guide-content__col guide-content__col--reverse">
            <div class="guide-descr">
              Вне зависимости от выбора, мы предлагаем высокий стандарт
              обслуживания и комфортные условия для проживания. Особое внимание,
              конечно, стоит уделить гостиницам в Байкальском регионе —
              симбиотическая связь культуры и природы наполняет спокойствием,
              способствует настоящему отдыху и, без сомнения, оставляет в памяти
              чувство уюта и тепла.
            </div>
          </div>
          <div class="guide-content__col guide-content__col--reverse">
            <h2 class="guide-title">Где остановиться?</h2>
          </div>
        </div>

        <div class="guide-content__row">
          <div class="guide-content__col guide-content__col--descr">
            <div class="guide-hotels__img-container">
              <img
                src="@/assets/img/home-hotels.jpg"
                alt=""
                class="guide-hotels__img"
              />
            </div>
          </div>
          <div class="guide-content__col guide-content__col--descr">
            <div class="guide-hotels-chips">
              <div class="guide-hotels-chips__item">
                <div class="guide-hotels-chips__descr">
                  Бронируйте номера не выходя из дома
                </div>
                <div
                  class="guide-hotels-chips__icon guide-hotels-chips__icon--booking"
                ></div>
              </div>

              <div class="guide-hotels-chips__item">
                <div class="guide-hotels-chips__descr">
                  Осмотрите пространство до приезда и выберите для себя
                  подходящий вариант
                </div>
                <div
                  class="guide-hotels-chips__icon guide-hotels-chips__icon--pointer"
                ></div>
              </div>

              <div class="guide-hotels-chips__item">
                <div class="guide-hotels-chips__descr">
                  В рамках квеста доступны дополнительные бонусы
                </div>
                <div
                  class="guide-hotels-chips__icon guide-hotels-chips__icon--petard"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideHotels",
};
</script>
