import Vue from "vue";
import Vuex from "vuex";

import news from "./news";
import feedback from "./feedback";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { news, feedback },
});
