<template>
  <div class="guide-slider">
    <swiper ref="mySwiper" :options="swiperOptions">
      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--11">
            с. Турка. Маяк
          </div>
          <div class="guide-slide__descr">
            То ли из баллады, то ли из сказки, живописный маяк в духе работ
            художников–маринистов в с. Турка заслуживает эстетического
            воздыхания каждого путешественника.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--12">
            Валун Черепаха
          </div>
          <div class="guide-slide__descr">
            Скрываясь в лучах закатного солнца, из воды величественно взрастает
            скальная форма — когда солнце заходит, она превращается в строгую
            черепаху, охраняющую побережье Байкала.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--13">
            с. Максимиха, музей Золотые пески
          </div>
          <div class="guide-slide__descr">
            Случай, когда весь путь зачаровывает: побережье, по которому
            предстоит проехаться, волшебный парк–музей с церквушкой и юртой, а
            на десерт — неописуемый вид на рельефный полуостров с необычным
            именем «Святой нос».
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--14">
            п. Усть-Баргузин. Мост
          </div>
          <div class="guide-slide__descr">
            По пути в Забайкальский национальный парк путешественника ждет
            необычное зрелище — романтический архив брошенных кораблей под
            мостом недалеко от пос. Усть–Баргузин.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--15">
            Забайкальский национальный парк
          </div>
          <div class="guide-slide__descr">
            Жемчужина Забайкалья, это место занимает особое место в наших
            сердцах. Природа производит впечатление не только в своем статичном
            состоянии, но и в подвижности; лисы, птицы — все спешат
            приветствовать гостей в своем доме.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--16">
            Перешеек Мягкая карга
          </div>
          <div class="guide-slide__descr">
            Захватывающая дух тропа в виде ровного полуовала проходит через
            урочище «Мягкая карга». В ярком свете текстуры песков золотятся,
            напоминая лежбище древнего ящера, стерегущего свои богатства.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--17">
            Полуостров Святой нос
          </div>
          <div class="guide-slide__descr">
            Место, где стоит задержаться, сделать глубокий вдох и расслабиться.
            Полуостров знаменит своими термальными источниками, воды которых
            остаются горячими даже когда кругом вьются белоснежные хлопья.
          </div>
        </div>
      </swiper-slide>

      <swiper-slide>
        <div class="guide-slide">
          <div class="guide-slide__img guide-slide__img--18">
            Ушканьи острова Нерпа-центр
          </div>
          <div class="guide-slide__descr">
            Запомните название на бурятском — Ушкан аралнууд, ведь именно здесь
            расположен самый сладкий источник воспоминаний. Разве можно забыть
            умильный взгляд байкальской нерпы?
          </div>
        </div>
      </swiper-slide>

      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

export default {
  name: "GuideSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 30,
        centeredSlides: true,
        autoplay: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        breakpoints: {
          500: {
            slidesPerView: 1.5,
            centeredSlides: false,
            spaceBetween: 30,
          },
          768: {
            slidesPerView: 2.5,
            centeredSlides: false,
            spaceBetween: 40,
          },
          1200: {
            slidesPerView: 3,
            centeredSlides: false,
            spaceBetween: 50,
          },
          1500: {
            slidesPerView: 3.5,
            centeredSlides: false,
            spaceBetween: 90,
          },
        },
      },
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    // console.log("Current Swiper instance object", this.swiper);
    // this.swiper.slideTo(3, 1000, false);
  },
};
</script>
