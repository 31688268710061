import Vue from "vue";
import Axios from "./axios";

export default (app) => {
  let inject = (name, plugin) => {
    let key = `$${name}`;
    app[key] = plugin;
    app.store[key] = plugin;

    Vue.use(() => {
      // eslint-disable-next-line
      if (Vue.prototype.hasOwnProperty(key)) {
        return;
      }
      Object.defineProperty(Vue.prototype, key, {
        get() {
          return this.$root.$options[key];
        },
      });
    });
  };

  // Inject dependencie in Vue
  Axios(app, inject);
};
