<template>
  <div class="home">
    <HomePath />
    <HomeHero />
    <Guide />
  </div>
</template>

<script>
import HomePath from "@/components/partials/HomePath.vue";
import HomeHero from "@/components/partials/HomeHero.vue";
import Guide from "@/components/partials/Guide.vue";

export default {
  name: "Home",
  components: { HomePath, HomeHero, Guide },
};
</script>
