import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    component: () => import("../views/About.vue"),
  },
  {
    path: "/baikal",
    name: "Baikal",
    component: () => import("../views/Baikal.vue"),
  },
  {
    path: "/ar-quest",
    name: "ARQuest",
    component: () => import("../views/ARQuest.vue"),
  },
  {
    path: "/partners",
    name: "Partners",
    component: () => import("../views/Partners.vue"),
  },
  {
    path: "/news",
    name: "News",
    component: () => import("../views/News.vue"),
  },
  {
    path: "/news/:id",
    name: "NewsDetail",
    component: () => import("../views/NewsDetail.vue"),
  },
  {
    path: "/contacts",
    name: "Contacts",
    component: () => import("../views/Contacts.vue"),
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
  {
    path: "/delete",
    name: "Delete",
    component: () => import("../views/Delete.vue"),
  },
  {
    path: "/doc",
    name: "Doc",
    component: () => import("../views/Doc.vue"),
  },
  // {
  //   path: "/ui-kit",
  //   name: "UIKit",
  //   component: () => import("../views/UI.vue"),
  // },
  {
    path: "*",
    name: "E404",
    component: () => import("../views/E404.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  linkActiveClass: "active",
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        // , offset: { x: 0, y: 10 }
      };
    } else {
      return {x: 0, y: 0};
    }
  },
});

export default router;
