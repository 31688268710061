<template>
  <div class="guide-section js-trigger-path" data-name="food">
    <div class="container">
      <div class="guide-content">
        <div class="guide-content__row">
          <div class="guide-content__col guide-content__col--reverse">
            <div class="guide-descr">
              Наш собственный сервис по доставке еды обеспечит вас блюдом на
              любой вкус всего в пару кликов.
            </div>
          </div>
          <div class="guide-content__col guide-content__col--reverse">
            <h2 class="guide-title">Доставка еды</h2>
          </div>
        </div>

        <div class="guide-content__row">
          <div class="guide-content__col guide-content__col--descr">
            <div class="guide-restaurants-chips mt-0">
              <div class="guide-restaurants-chips__item">
                <div
                  class="guide-restaurants-chips__icon guide-restaurants-chips__icon--timer"
                ></div>
                <div class="guide-restaurants-chips__descr">
                  Заказ будет доставлен в ближайшее время
                </div>
              </div>

              <div class="guide-restaurants-chips__item">
                <div
                  class="guide-restaurants-chips__icon guide-restaurants-chips__icon--app"
                ></div>
                <div class="guide-restaurants-chips__descr">
                  Ничего не нужно скачивать — сервис уже встроен в приложение GO
                  AND GUIDE 360
                </div>
              </div>

              <div class="guide-restaurants-chips__item">
                <div
                  class="guide-restaurants-chips__icon guide-restaurants-chips__icon--petard"
                ></div>
                <div class="guide-restaurants-chips__descr">
                  Скидка на первый заказ, возможность бесплатной доставки и
                  несколько вариантов оплаты
                </div>
              </div>
            </div>
          </div>

          <div class="guide-content__col guide-content__col--descr">
            <div class="guide-hotels__img-container">
              <img
                src="@/assets/img/home-food.jpg"
                alt=""
                class="guide-hotels__img"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GuideFood",
};
</script>
