import axios from "axios";
import settings from "@/settings";

export default (app, inject) => {
  axios.defaults.baseURL = settings.APP_URL;

  axios.interceptors.response.use(
    function (response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // send report about xhr errors

      return Promise.reject(error);
    }
  );

  // console.log(settings);

  inject("axios", axios);
};
