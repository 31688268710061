<template>
  <section class="home-hero">
    <div class="container">
      <div class="home-hero__content">
        <h1 class="home-hero__title">
          Захватывающие виртуальные туры по Байкальскому региону
        </h1>
        <p class="home-hero__descr">
          Ощути самобытность региона, погрузись в культуру, историю и уникальную
          природу Байкала. Почувствуй мощную и неповторимую энергетику.
        </p>
        <div class="home-hero-chips">
          <div class="home-hero-chips__item" @click="e => e.currentTarget.classList.toggle('clicked')"
            @mouseleave="e => e.currentTarget.classList.remove('clicked')">
            <div class="home-hero-chips__icon home-hero-chips__icon--earth"></div>
            <div class="home-hero-chips__descr">
              Уникальный тур 360 по Байкальскому региону
            </div>
            <div class="home-hero-chips__blank">
              <div class="home-hero-chips__blank-badge"></div>
            </div>
          </div>

          <div class="home-hero-chips__item">
            <a href="/booking">
              <div class="home-hero-chips__icon home-hero-chips__icon--hotels"></div>
              <div class="home-hero-chips__descr">
                Бронирование отелей и гостевых домов из приложения
              </div>
            </a>
          </div>

          <div class="home-hero-chips__item" @click="e => e.currentTarget.classList.toggle('clicked')"
            @mouseleave="e => e.currentTarget.classList.remove('clicked')">
            <div class="home-hero-chips__icon home-hero-chips__icon--map"></div>
            <div class="home-hero-chips__descr">
              Создайте свой персональный маршрут за 5 минут
            </div>
            <div class="home-hero-chips__blank">
              <div class="home-hero-chips__blank-badge"></div>
            </div>
          </div>

          <div class="home-hero-chips__item" @click="e => e.currentTarget.classList.toggle('clicked')"
            @mouseleave="e => e.currentTarget.classList.remove('clicked')">
            <div class="home-hero-chips__icon home-hero-chips__icon--ar"></div>
            <div class="home-hero-chips__descr">
              Участвуйте в первом на Байкале квесте дополненной реальности
            </div>
            <div class="home-hero-chips__blank">
              <div class="home-hero-chips__blank-badge"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "HomeHero",
};
</script>
