<template>
  <div id="app">
    <AppHeader />

    <transition name="fade" mode="out-in">
      <router-view />
    </transition>
    <AppFooter />
  </div>
</template>

<script>
import "normalize.css";
import "@/assets/styles/main.sass";

import AppHeader from "@/components/base/AppHeader.vue";
import AppFooter from "@/components/base/AppFooter.vue";

export default {
  components: { AppHeader, AppFooter },
};
</script>
