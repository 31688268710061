// import qs from "qs";

export default {
  namespaced: true,
  state: {
    errors: {
      sendFeedback: {},
    },
  },

  mutations: {
    SET_ERRORS(state, error) {
      state.errors[error.key] = error.val;
    },
  },
  actions: {
    async sendFeedback({ commit, dispatch }, payload) {
      dispatch("resetSendFeedback");

      const url = "/feedback";

      // data = qs.stringify(data);

      try {
        await this.$axios.post(
          url,
          payload
          // {
          //   headers: { "content-type": "application/x-www-form-urlencoded" },
          // }
        );
      } catch (e) {
        if (e.response && e.response.data) {
          console.log(e.response.data);
          commit("SET_ERRORS", {
            key: "sendFeedback",
            // val: e.response.data,
            val: { error: e.response.data },
          });
        } else {
          console.log("Ошибка " + e.message);
          commit("SET_ERRORS", {
            key: "sendFeedback",
            val: { error: "Ошибка сетевого запроса" },
          });
        }
      }
    },
    resetSendFeedback({ commit }) {
      commit("SET_ERRORS", {
        key: "sendFeedback",
        val: {},
      });
    },
  },
};
