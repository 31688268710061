<template>
  <div class="guide-section js-trigger-path" data-name="tour">
    <div class="container">
      <div class="guide-content">
        <div class="guide-content__row">
          <div class="guide-content__col">
            <h2 class="guide-title">
              Едем в гости <br />
              к нерпе
            </h2>
          </div>
          <div class="guide-content__col">
            <div class="guide-descr">
              На берегах пресного моря можно обнаружить не только невероятные
              скальные и песочные пейзажи, но и самое милое – и, кстати,
              единственное – млекопитающее Байкала. Путь до Ушканьих островов
              предстоит неблизкий, но кто не рискнет его преодолеть ради встречи
              с легендой — нерпой, сверкающей кружками черных приветливых глаз?
            </div>
          </div>
        </div>
      </div>
    </div>

    <GuideSlider />
  </div>
</template>

<script>
import GuideSlider from "@/components/partials/GuideSlider.vue";

export default {
  name: "GuideTours",
  components: { GuideSlider },
};
</script>
